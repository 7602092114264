<!--
	This is the Orders List page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>
    <div v-if="pageFlag === 'list'">
      <!-- Orders List header -->
      <!-- / Orders List header -->

      <!-- Orders List card -->
      <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">

        <!-- Table search -->
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="12" class="text-left">
              <a-select
                  show-search
                  allowClear
                  placeholder="选择租户"
                  style="width: 200px;margin-right: 8px"
                  size="small"
                  v-model="query.tenantId"
                  :filter-option="filterOption"
                  @change="getData"
              >
                <a-select-option v-for="tenant in tenantList" :key="tenant.id" :value="tenant.id" >
                  {{ tenant.name }}
                </a-select-option>
              </a-select>
              <a-input-search placeholder="请输入查询信息" style="max-width: 200px;" v-model="query.name" @search="getData" size="small" />
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" size="small" @click="visible = true">{{ $t('create') }}</a-button>
            </a-col>
          </a-row>
        </div>
        <!-- / Table search -->

        <!-- Orders table -->
        <a-table class="mt-20"
                 :columns="columns"
                 :data-source="data"
                 rowKey="id"
                 :loading="loading"
                 :pagination="{pageSize: pageSize,current: pageNum,total:total}"
                 :scroll="{ x: true, y: true }"
                 @change="handleTableChange"
        >


          <template slot="status" slot-scope="status">
            <span v-if="status === 1" style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('enable') }}</span>
            <span v-else style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('invalid') }}</span>
          </template>

          <template slot="operation" slot-scope="row">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="dash" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <span @click="edit(row)" style="cursor: pointer">
                    <a-icon type="edit" style="cursor: pointer"/> {{ $t('edit') }}
                  </span>
                </a-menu-item>
                <a-menu-item key="2">
                  <a-popconfirm :title="$t('delConfirmMsg')" :ok-text="$t('yes')" :cancel-text="$t('no')" @confirm="del(row.id)">
                    <a-icon type="delete" style="cursor: pointer"/> {{ $t('delete') }}
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <span style="cursor: pointer" @click="openModal(row)">
                    <a-icon type="snippets"/> {{ $t('role.menu') }}
                  </span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>

        </a-table>
        <!-- / Orders table -->

      </a-card>
      <a-drawer
          :title="drawerTitle"
          placement="right"
          :closable="true"
          :visible="visible"
          width="40%"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
      >
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0 }" :loading="drawerLoading">
          <a-form-item class="mb-10" :label="$t('tenant.tenant')" :colon="false" required>
            <a-select
                show-search
                allowClear
                placeholder="选择租户"
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.tenantId"
                :filter-option="filterOption"
                @change="getRoleTypeList"
            >
              <a-select-option v-for="tenant in tenantList" :key="tenant.id" :value="tenant.id" >
                {{ tenant.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('role.type')" :colon="false" required>
            <a-select
                show-search
                allowClear
                placeholder="选择类型"
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.type"
                :filter-option="filterOption"
            >
              <a-select-option v-for="type in roleTypeList" :key="type.id" :value="type.id" >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('role.code')" :colon="false" required>
            <a-input v-model="form.code" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('role.name') + ' [中文]'" :colon="false" required>
            <a-input v-model="form.nameZh" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('role.name') + ' [EN]'" :colon="false" required>
            <a-input v-model="form.nameEn" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('role.status')" :colon="false" >
            <a-select
                show-search
                allowClear
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.status"
                :filter-option="filterOption"
            >
              <a-select-option :value="1" >
                {{ $t('enable') }}
              </a-select-option>
              <a-select-option :value="0" >
                {{ $t('invalid') }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="text-align: right">
            <a-input v-model="form.id" size="small" type="hidden"/>
            <a-button type="primary" html-type="submit" @click="submit"  size="small">
              {{ $t('submit') }}
            </a-button>
          </a-form-item>
        </a-card>
      </a-drawer>
      <a-modal v-model="modalVisible" :title="$t('role.menu')" :loading="modalLoading"
               :dialog-style="{ top: '20px'}" :bodyStyle="{maxHeight: '70vh', overflowY: 'scroll'}" @cancel="handleCancel">
        <template slot="footer">
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="submitRoleMenu">
            确认
          </a-button>
        </template>
        <a-tree
            v-model="checkedKeys"
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="treeData"
            @expand="onExpand"
            @select="onSelect"
            @check="onCheck"
        />
      </a-modal>

    </div>
  </div>

</template>

<script>

import {constructMenuTree, extractMenuCode, extractMenuCodeAndIdRef, rebuildMenus} from "../../utils/common";

export default {
  data() {
    return {
      postFlag: true,
      loading: false,
      uploadLoading: false,
      drawerLoading: false,
      modalLoading: false,
      visible: false,
      dropVisible: false,
      modalVisible: false,
      drawerTitle: this.$t('create'),
      pageFlag: 'list',
      imageUrl: '',
      // Table columns
      columns: [
        {
          title: this.$i18n.t('role.name'),
          dataIndex: 'name',
          // scopedSlots: { customRender: 'typeName' },
          width: 150
        },
        {
          title: this.$i18n.t('role.code'),
          dataIndex: 'code',
          // scopedSlots: { customRender: 'code' },
          width: 200
        },
        {
          title: this.$i18n.t('tenant.tenant'),
          dataIndex: 'tenantName',
          width: 150
        },
        {
          title: this.$i18n.t('role.type'),
          dataIndex: 'typeName',
          width: 150
        },
        {
          title: this.$i18n.t('role.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: this.$i18n.t('lastModifiedDate'),
          dataIndex: 'lastModifiedDate',
          width: 180
        },
        {
          title: this.$i18n.t('operation'),
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        },
      ],

      // Table rows
      data: [],

      // Table page size
      pageSize: 10,
      pageNum: 1,
      total: 0,

      // Table search query
      query: {
        name: null,
        tenantId: undefined
      },

      // Table's selected rows
      selectedRowKeys: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: null,
        tenantId: undefined,
        code: null,
        nameZh: null,
        nameEn: null,
        type: undefined,
        lastModifiedDate: null,
        status: 1
      },
      tenantList: [],
      roleTypeList: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeData: [],
    }
  },
  mounted() {
    this.getTenantList()
    this.getData()
  },
  methods: {
    openModal(row) {
      this.getMenuChecked(row.id)
      this.getMenuTree()
      this.form = row
    },
    getMenuTree() {
      let codes = []
      let ref = {}
      this.$store.dispatch('GET_MENU_TREE').then(res => {
        if(res.errorCode === '0') {
          codes = extractMenuCode(res.data, codes)
          ref = extractMenuCodeAndIdRef(res.data, ref)
          this.$store.commit('SET_MENU_CODES', codes)
          const routes = rebuildMenus(this.$router.options.routes, codes, false)
          this.treeData = constructMenuTree(routes, ref, this.$i18n)
          this.modalVisible = true
        }
      })
    },
    getMenuChecked(roleId) {
      const params = {
        roleId: roleId
      }
      this.$store.dispatch('MENU_CHECKED', params).then(res => {
        if(res.errorCode === '0') {
          this.expandedKeys = res.data
          this.checkedKeys = res.data
        }
      })
    },
    getTenantList() {
      this.$store.dispatch('TENANT_LIST', {params: {pageNum: 1,pageSize: 9999}, data: {}}).then(res => {
        if (res.errorCode === '0') {
          this.tenantList = res.data.list
        }
      })
    },
    getRoleTypeList(tenantId) {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        tenantId: tenantId,
        typeCode: 'ROLE_TYPE'
      }
      this.$store.dispatch('PARAM_LIST_BY_TYPE_CODE', params).then(res => {
        if (res.errorCode === '0') {
          this.roleTypeList = res.data.parameterDTOS
        }
      })
    },
    afterVisibleChange() {},
    onSubmit() {},
    onClose() {
      this.visible = false
      this.resetForm()
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // Event listener for input change on table search field.
    onSearch() {
      this.getData()
    },

    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    },
    getData() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const searchForm = {
        name: this.query.name,
        tenantId: this.query.tenantId
      }

      this.$store.dispatch('ROLE_LIST', {params: params, data: searchForm}).then(res => {
        if (res.errorCode === '0') {
          this.data = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    submit() {
      this.drawerLoading = true
      if (this.postFlag) {
        this.postFlag = false
        this.$store.dispatch("SAVE_ROLE", this.form).then(res => {
          this.drawerLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.visible = false
            this.resetForm()
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.drawerLoading = false
          this.postFlag = true
          this.$message.error(err)
        })

      }
    },
    resetForm() {
      this.form = {
        id: null,
        tenantId: null,
        code: null,
        nameZh: null,
        nameEn: null,
        domain: null,
        lastModifiedDate: null,
        status: 1
      }
      this.drawerTitle = this.$t('create')
    },
    edit(row) {
      this.drawerTitle = this.$t('edit')
      this.getRoleTypeList()
      this.form = row
      this.visible = true
    },
    handleTableChange(pagination) {
      this.pageNum = pagination.current
      this.total = pagination.total
      this.getData()
    },
    del(id) {
      const param = {
        id: id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.loading = true
        this.$store.dispatch('DEL_ROLE', param).then(res => {
          this.postFlag = true
          if (res.errorCode === '0') {
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(err => {
          this.postFlag = true
          this.loading = false
          this.$message.error(err)
        })
      }
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    handleCancel() {
      this.modalVisible = false
      this.resetForm()
    },
    submitRoleMenu() {
      const params = {
        roleId: this.form.id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.modalLoading = true
        this.$store.dispatch('SAVE_ROLE_MENU', {params: params, data: this.checkedKeys}).then(res => {

          this.modalLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.modalVisible = false
            this.resetForm()
            this.$message.success(res.message, 1)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.postFlag = true
          this.modalLoading = false
          this.$message.error(err)
        })
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.ant-select::v-deep .ant-select-selection__placeholder {
  font-weight: normal;
}
.ant-input-search::v-deep input::placeholder {
  font-weight: normal;
}
.ant-modal-content::v-deep .ant-modal-content {
  max-height: 450px;
  overflow-y: scroll;
}
</style>